import React, { Fragment } from "react";
import { StaticQuery, graphql } from "gatsby";
import BackgroundImage from "gatsby-background-image";

const Contact = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          contact: file(relativePath: { eq: "contacts-bg.png" }) {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={data => (
        <Fragment>
          <BackgroundImage
            Tag="section"
            className="bg-secondary bg-cover bg-no-repeat pt-5 pb-4 contact-bg"
            fluid={data.contact.childImageSharp.fluid}
            backgroundColor={`#040e18`}
          >
            <div className="container py-2">
              <h2 className="h3 block-title text-center">Contact Us</h2>
              <form
                className="needs-validation pt-4 pb-5"
                name="contact"
                method="POST"
              >
                <input type="hidden" name="form-name" value="contact" />
                <div className="row">
                  <div className="col-md-3 col-sm-6 form-group">
                    <label htmlFor="cont-name">
                      Your Name{" "}
                      <span className="text-danger font-weight-medium">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      id="cont-name"
                      name="name"
                      placeholder="John Doe"
                      required=""
                    />
                    <div className="invalid-feedback">
                      Please fill in your name!
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6 form-group">
                    <label htmlFor="cont-company">Company Name</label>
                    <input
                      className="form-control"
                      type="text"
                      id="cont-company"
                      name="company"
                      placeholder="Google Inc"
                    />
                  </div>
                  <div className="col-md-3 col-sm-6 form-group">
                    <label htmlFor="cont-email">
                      Email Address{" "}
                      <span className="text-danger font-weight-medium">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="email"
                      id="cont-email"
                      name="email"
                      placeholder="johndoe@example.com"
                      required=""
                    />
                    <div className="invalid-feedback">
                      Please provide valid email address!
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6 form-group">
                    <label htmlFor="cont-phone">
                      Phone Number{" "}
                      <span className="text-danger font-weight-medium">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      id="cont-phone"
                      name="phone"
                      placeholder="+6587654321"
                      required=""
                    />
                    <div className="invalid-feedback">
                      Please provide valid phone number
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="cont-message">
                    Message{" "}
                    <span className="text-danger font-weight-medium">*</span>
                  </label>
                  <textarea
                    className="form-control"
                    id="cont-message"
                    name="message"
                    rows="8"
                    placeholder="Type your message..."
                    required=""
                  />
                  <div className="invalid-feedback">
                    Please type your message!
                  </div>
                </div>
                <div className="text-center">
                  <button
                    className="btn btn-style-4 btn-icon-left btn-gradient"
                    type="submit"
                  >
                    <i className="fe-icon-mail text-md" />
                    Send Message
                  </button>
                  <p className="d-none">
                    <label htmlFor="bot-field">
                      Don’t fill this out if you're human:
                    </label>
                    <input name="bot-field" id="bot-field" />
                  </p>
                  <p className="pt-4">
                    or email us at{" "}
                    <a href="contact@tech2reach.com">contact@tech2reach.com</a>
                  </p>
                </div>
              </form>
            </div>
          </BackgroundImage>
        </Fragment>
      )}
    />
  );
};

export default Contact;
