import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import BackgroundImage from "gatsby-background-image";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Contact from "../components/contact";

const ContactUs = () => {
  const data = useStaticQuery(graphql`
    query {
      hero: imageSharp(fluid: { originalName: { eq: "contact-bg.jpg" } }) {
        fluid(quality: 90, maxWidth: 1920) {
          presentationWidth
          presentationHeight
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  `);

  return (
    <Layout dark={true} absolute={false}>
      <SEO
        title="Contact Us"
        pathname="/contact-us/"
        image={data.hero.fluid}
        keywords={[
          "singapore",
          "hire",
          "tech",
          "foreign",
          "talent",
          "offshore",
          "outsource",
          "programmer",
          "software",
          "developer"
        ]}
      />
      <BackgroundImage
        className="bg-cover bg-center bg-no-repeat py-5"
        fluid={data.hero.fluid}
      >
        <span className="bg-overlay" style={{ opacity: 0.5 }}></span>
        <div className="container bg-content py-md-5 text-center px-5">
          <h2 className="text-white">Get in touch with us!</h2>
          <p className="text-white opacity-80 pb-3">
            We would love to hear from you. Give us a call, or fill out the form
            below, and we’ll be in touch right away!
          </p>
        </div>
      </BackgroundImage>
      <Contact />
    </Layout>
  );
};

export default ContactUs;
